<template>
    <div class="s-content">
        <div class="s-content-list-row">
            <div class="s-content-image-frame ">
                <div class="s-content-image-loading "></div>
            </div>
            <div class="s-ml-16 s-fullWidth" :class="isMobile? 's-mt--30':''">
                <h4 class="s-heading-loading"></h4>
                <div v-if="!isMobile" class="s-center-flex">
                    <p class="s-text-loading s-wd-112"></p>
                    <p class="s-mlr-16">|</p>
                    <div class="s-center-flex s-wrap">
                        <div
                        class="s-text-center"
                        v-for="(a, index) in 3"
                        :key="`tag-${index}`">
                            <p class="s-text-loading s-wd-100 s-ml-5"></p>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div>
                        <p class="s-text-loading"></p>
                    </div>
                    <div class="s-center-flex s-wrap">
                        <div class=" s-text-center" v-for="(a, index) in 3" :key="`tag-${index}`">
                             <p class="s-text-loading s-wd-100 s-ml-5"></p>
                        </div>
                    </div>
                </div>
                <div v-if="!isMobile" class="s-text-justify s-line-clamp s-line-clamp-3">
                    <p class="s-text-loading "></p>
                    <p class="s-text-loading s-mt-5"></p>
                    <p class="s-text-loading s-mt-5"></p>
                </div>
                <div>
                    <h6 class="s-text-loading s-mt-5 s-wd-80"></h6>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            isMobile: 'getIsMobile'
        })
    }
}
</script>